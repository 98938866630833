<template>
<b-card  @keyup.enter="filterRequired('trackNumber', 'input')" v-if="dataUser">
  <filter-swapper v-show="!loading.first" :controlHeightButtons="controlHeight" class="mb-1">
    <template #slot1>
      <form-render :form.sync="form" :fields="fieldsForm" @send="filterRequired('trackNumber', 'input')" >
        <template #buttonModalOT>
          <b-button v-b-modal.idUploadOTAttachment variant="outline-warning">
            <feather-icon  icon="UploadIcon" class="mr-50"/>Subir documentos
          </b-button>
        </template>
        <template #tagsMarketplaceSlot>
          <div class="row">
            <div class="col-12">
              <span class="label-form">Marketplace y Sellers </span>
              <span class="text-success text-capitalize font-weight-bolder"  v-if="marketplaceSelect && marketplaceSelect.id">({{marketplaceSelect.organization_text}})</span>
                <v-select
                  v-show="!hideMkpSelect"
                  v-model="marketplaceSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Escoge marketplace"
                  label="text"
                  :options="marketplaceArray"
                  @input="changeMarketplace"
                  :disabled="loading.shippers"
                />
            </div>
          </div>
        </template>
        <template #buttonsGroupSlot>
          <b-row align-v="center">
            <b-col cols="12" md="9" lg="10" xl="10" class="mt-2">
              <b-button-group class="w-full">
                <b-button @click="filterRequired('info', 'button')"
                  class="btn-check "
                  :class="filterStatus === 'info' ? 'active': ''"
                  variant="outline-warning"
                  :title="$t('Se requiere información')"
                  :disabled="loading.tickets">
                  <b-spinner small v-if="loading.tickets && filterStatus === 'info'"/>
                  {{ $t('Se requiere información') }}
                </b-button>
                <b-button @click="filterRequired('doc', 'button')"
                  class="btn-check "
                  :class="filterStatus === 'doc' ? 'active': ''"
                  variant="outline-warning"
                  :title="$t('Se requiere documentación')"
                  :disabled="loading.tickets">
                  <b-spinner small v-if="loading.tickets && filterStatus === 'doc'" />
                  {{ $t('Se requiere documentación') }}
                </b-button>
              </b-button-group>
            </b-col>
            <b-col cols="12" md="3" lg="2" xl="2" class="mt-2 pr-1">
              <b-form-checkbox
                v-model="selectFulfillment"
                v-if="organizationData.fulfillment"
                value="fulfillment"
                class="custom-control-warning"
              >
                {{ $t('Fulfillment') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </template>
        <template #exportGroupSlot>
            <b-row>
              <b-col cols="5" lg="2" xl="4" offset-lg="7" offset-xl="1" class="mt-2">
                <b-button @click="exportFile()" 
                  class="btn-check  btn-block pl-1" 
                  variant="outline-success"
                  :title="$t('Exportar filtro actual')"
                  :disabled="loading.tickets" >
                  <template v-if="loading.exportFile">
                    <b-spinner small />
                    <span class="align-middle">{{ $t('Exportando') }}</span>
                  </template>
                  <template v-else>                
                    <feather-icon icon="DownloadIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('Exportar') }}</span>
                  </template>
                </b-button>
              </b-col>
              <b-col cols="7" lg="3" xl="7" class="mt-2">
                <b-button @click="showModalAttachment()" 
                  class="btn-check  btn-block pl-1 pr-1"
                  variant="outline-warning"
                  :title="$t('Subir archivo de respuesta')"
                  :disabled="loading.tickets">
                  <feather-icon icon="UploadIcon" class="mr-50"/>
                  <span class="align-middle">{{ $t('Subir respuestas') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row >
              <b-col cols="12">
                <b-button  variant="flat-primary" class="p-0 mt-03 push-right" v-b-modal.guideTemplate>
                  <feather-icon icon="InfoIcon"/>{{ $t('¿Como completar la plantilla?') }}
                </b-button>
              </b-col>
            </b-row>
        </template>
      </form-render>
    </template>
  </filter-swapper>
  <div v-show="!loading.first " class="table--container">
    <table-render  id="table-tickets-pending" :schema="schema" :actions="actions" :rows="rows" :loading="loading.tickets">
      <template #statusSlot="scope">
        <b-container>
          <b-row class="mb-03">
            <b-badge variant="warning">{{scope.rowdata.clientName}}</b-badge>
          </b-row>
          <b-row>
            <b-badge :class="selectClassCodeStatus(scope.rowdata.classStatus)">{{scope.rowdata.statusTicket}}</b-badge>
          </b-row>
        </b-container>
      </template>
      <template #trackingInfoSlot="scope">
        <span >{{scope.rowdata.importedId}}</span>
        <br>
        <br>
        <span >{{scope.rowdata.carrierName}}</span>
        <br>
        <span >{{ $t('OT') }}: {{scope.rowdata.trackNumber}}</span>
      </template>
      <template #dateStatusSlot="scope" >
        <span>{{scope.rowdata.statusDate}}</span> 
      </template>
      <template #companyNameSlot="scope">
        <span>{{scope.rowdata.companyName}}</span>
        <br>
        <b-badge variant="light-danger">{{scope.rowdata.statusFulfillment}}</b-badge>
      </template>
      <template #deliveryStatusSlot="scope">
        <b-badge variant="light-secondary">{{scope.rowdata.statusDeliveryName}}</b-badge>
      </template>
      <template #waitingDaysSlot="scope">
        <p class="m-0" :class="scope.rowdata.classWaitingDays">{{scope.rowdata.textWaitingDays}}
        </p>
        <span class="bg-lighten--2">{{ $t('En espera de respuesta.') }}</span>
      </template>
      <template #actionsSlot="scope">
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Delivery" class="p-0" @click="openDelivery(scope.rowdata)"><feather-icon icon="TruckIcon" /></b-button>
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Ticket" class="p-0" @click="openTicket(scope.rowdata)"><feather-icon icon="FileTextIcon" /></b-button>
      </template>
    </table-render>
   <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>

  </div>
  <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
  <div class="table-render-skeleton" v-if="loading.first">
  <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
  </div>
  <UploadAttachment></UploadAttachment>
  <UploadOTAttachment></UploadOTAttachment>
  <GuideTemplate></GuideTemplate>
</b-card>
</template>
<style scoped>

</style>
<script>
import { BButtonGroup, BButton, BSpinner, BContainer, BRow, BCol, BBadge } from 'bootstrap-vue'
import UploadAttachment from './component/UploadAttachment.vue'
import UploadOTAttachment from './component/UploadOTAttachment.vue'
import GuideTemplate from '@/views/modules/tickets-pending/component/GuideTemplate.vue'
import vSelect from 'vue-select'
import BaseServices from '@/store/services/index'
import { environment } from '@/environments/environment'

export default {
  components: {
    // BFormTags,
    BButtonGroup, 
    BButton,
    BSpinner,
    UploadAttachment,
    UploadOTAttachment,
    GuideTemplate,
    BContainer,
    BRow,
    BCol,
    BBadge,
    vSelect
  },
  data() {
    return {
      schema: [],
      actions: [],
      rows: [],
      filterStatus: '',
      form: {
        tracking_number: ['']
      },
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: false,
        calculateCountPage: true
      },
      fieldsForm: [],
      loading: {
        first: false,
        tickets: false,
        exportFile: false,
        shippers: true
      },
      metaFilter: {},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      selectFulfillment: false,
      organizationData: {},
      marketplaceArray: [],
      marketplaceSelect: [],
      hideMkpSelect: false,
      baseService: new BaseServices(this)
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  mounted() {
    this.checkConditionUser()
  },
  watch: {
    'pagination.page'() {
      this.paginationFunction('page')
    },
    'pagination.limit'() {
      this.paginationFunction('limitPage')
    },
    'selectFulfillment' () {
      this.filterRequired(this.filterStatus, 'fulfillment')
    },
    'loading.tickets' () {
      this.fieldsForm[0].disabled = this.loading.tickets
    }
  },
  methods: {
    changeMarketplace() {
      this.filterRequired(this.filterStatus, 'mkp')
    },
    checkConditionUser() {
      !!this.dataUser ? this.firstLoad() : setTimeout(() => {
        this.$router.go(this.$router.currentRoute)
      }, 1000)
    },
    firstLoad() {
      this.setInitialData()
      this.getAllInitialData()
    },
    exportFile() {
      this.loading.tickets = true
      this.loading.exportFile = true
      const filterParams = this.getDataFilter(this.filterStatus)
      const queryParams = {...this.setQueryParams(this.metaFilter, false), ...filterParams}
      const params = {
        shipper_id: this.dataUser.shipper?.id ? this.dataUser.shipper.id : ''
      }

      this.baseService.callService('exportTickets', queryParams, params)
        .then(response => {
          setTimeout(() => {
            window.open(response.data.url, '_blank')
          }, 2000)
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(end => {
          this.returnVar()
        })
    },
    filterRequired(required, action = '') {
      const resetRequired = this.filterStatus === required && 
                            this.filterStatus !== 'trackNumber' && 
                            action !== 'page' &&
                            action !== 'fulfillment' &&
                            action !== 'mkp' &&
                            action !== 'seller'
      if (resetRequired) required = ''
      if (action !== 'page') {
        this.pagination.page = 1
        this.pagination.calculateCountPage = true
      } else {
        this.pagination.calculateCountPage = false
      }
      const filter = this.getDataFilter(required, true)
      this.getTickets(filter)
    },
    formatDate(date) {
      return this.$options.filters.dbDateToFormat(date, 'dd-LL-y T')
    },
    getDataFilter(required, sourceFilter = false) {
      const filter = { 
        current_status: 'ISSUE_INFO_REQUESTED,COMPLAINT_INFO_REQUESTED,COMPENSATION_INFO_REQUESTED,ISSUE_DOC_REQUESTED,COMPLAINT_DOC_REQUESTED,COMPENSATION_DOC_REQUESTED,DISAGREEMENT_INFO_REQUESTED,DISAGREEMENT_DOC_REQUESTED'
      }
      switch (required) {
      case 'info':
        this.filterStatus = 'info'
        this.form.tracking_number = ['']
        if (!sourceFilter) filter.filter_name = this.$t('Se requiere información')
        filter.current_status = 'ISSUE_INFO_REQUESTED,COMPLAINT_INFO_REQUESTED,COMPENSATION_INFO_REQUESTED,DISAGREEMENT_INFO_REQUESTED'
        break
      case 'doc':
        this.filterStatus = 'doc'
        this.form.tracking_number = ['']
        if (!sourceFilter) filter.filter_name = this.$t('Se requiere documentación')
        filter.current_sub_status = 'ISSUE_MANIFEST,COMPLAINT_BUSINESS_DOCUMENT,COMPLAINT_MANIFEST,COMPENSATION_MANIFEST,COMPENSATION_BUSINESS_DOCUMENT,DISAGREEMENT_MANIFEST,DISAGREEMENT_PHOTO_EVIDENCE,DISAGREEMENT_BUSINESS_DOCUMENT,DISAGREEMENT_REVIEW_MISSING_PRODUCT,DISAGREEMENT_REVIEW_MISSING_PACKAGE,DISAGREEMENT_REVIEW_PRESUMED_LOST,DISAGREEMENT_DOC_COMPLETED,COMPENSATION_DOC_COMPLETED,COMPENSATION_PRE_APPROVED_DOC_REQUEST,DISAGREEMENT_PRE_APPROVED_DOC_REQUEST'
        delete (filter.current_status)
        // filter.current_status = 'ISSUE_DOC_REQUESTED,COMPLAINT_DOC_REQUESTED,COMPENSATION_DOC_REQUESTED,DISAGREEMENT_DOC_REQUESTED'
        break
      case 'trackNumber':
        this.filterStatus = 'trackNumber'
        if (!sourceFilter) filter.filter_name = this.$t('Búsqueda mis gestiones pendientes')
        if (this.form.tracking_number?.length > 0) filter.tracking_number = this.form.tracking_number.join() ?? ''
        break
      default :
        this.filterStatus = ''
        filter.filter_name = this.$t('Mis gestiones pendientes')
        break
      }
      if (this.selectFulfillment) {
        filter.fulfillment = true
        if (!sourceFilter) filter.filter_name = this.$t('Entregados a fulfillment')
      }
      return filter
    },
    getAllInitialData() {
      this.loading.tickets = true
      this.loading.first = true
      this.setDataCompany()
      const queryParams = this.setQueryParams(this.metaFilter, true)
      const params = {
        shipper_id: this.dataUser.id ? this.dataUser.id : ''
      }
      const arrServices = [
        {
          name: 'getTickets',
          queryParams: {
            ...queryParams
          },
          params: {
            ...params
          }
        }]
      this.baseService
        .callMultipleServices(arrServices, true)
        .then((response) => {
          if (response.getTickets.data) this.setDataRow(response.getTickets.data, response.getTickets.pages)
        })
        .catch((err) => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err
          }))
        })
        .finally((end) => {
          if (['admin', 'superadmin'].includes(this.dataUser.role)) this.getMarketplaces()
          if (this.dataUser.role === 'marketplace') this.getShippers()
          this.returnVar()
        })
    },
    getMarketplaces() {
      this.loading.shippers = true
      const queryParams = {
        // organization_type: 1,
        paginate_by: 100
      }
      const params = {
      }
      this.baseService.callService('getMarketplaces', queryParams, params)
        .then(response => {
          if (response.data) this.setDataCompanySelector(response.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading.shippers = false
        })
    },
    getTickets(prevQueryParams) {
      this.loading.tickets = true
      const queryParams = this.setQueryParams(prevQueryParams, true)
      const params = {
        shipper_id: this.dataUser.id ? this.dataUser.id : ''
      }
      this.baseService.callService('getTickets', queryParams, params)
        .then(response => {
          this.setDataRow(response.data, response.pages)
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(end => {
          this.returnVar()
        })
    },
    getShippers() {
      this.loading.shippers = true
      const queryParams = {
      }
      const params = {
        shipper_id: this.dataUser.id ? this.dataUser.id : '',
        organization_id: this.marketplaceSelect.id ?? this.dataUser.organization.id ?? 0
      }
      this.baseService.callService('getShipper', queryParams, params)
        .then(response => {
          this.setDataCompanySelector(response.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading.shippers = false
        })
    },
    paginationFunction (source) {
      // Buscar nuevos tickets según página seleccionada
      if (!this.loading.tickets) {
        this.filterRequired(this.filterStatus, source)
      }
    },
    returnVar() {
      this.loading.tickets = false
      this.loading.first = false
      this.loading.exportFile = false
    },
    selectClassCodeStatus(code = '') {
      let className = ''
      switch (code) {
      case 'ISSUE':
        className = 'badge-light-warning'
        break
      case 'COMPLAINT':
        className = 'badge-light-danger'
        break
      case 'DISAGREEMENT':
        className = 'badge-light-primary'
        break
      case 'CLOSED':
        className = 'badge-light-success'
        break
      }
      return className
    },
    setDataCompany () {
      this.organizationData = {
        fulfillment : false
      }
      if (['admin', 'superadmin'].includes(this.dataUser.role)) {
        this.organizationData.fulfillment = true
      } else {
        const organizationCode = this.dataUser.organization?.id
        if (organizationCode !== null) {
          const params = {
            organization_id: organizationCode
          }
          this.baseService.callService('getOrganization', {}, params)
            .then(response => {
              this.organizationData = {
                fulfillment : response.data.configurations_pending?.fulfillment ?? false
              }
            })
            .catch(err => {
              this.organizationData = {
                fulfillment : false
              }
            })
        }
      }
    },
    setClassField (field) {
      let response = ''
      this.hideMkpSelect  = ['admin', 'superadmin'].includes(this.dataUser.role) || this.dataUser.role === 'marketplace'
      switch (field) {
      case 'OT':
        response = this.hideMkpSelect ? 'col-12 col-xl-5 container-info' : 'col-12 container-info'
        break
      case 'MKP':
        response = this.hideMkpSelect ? 'col-12 col-xl-5 container-info tagsMarketplace' : 'hidden'
        break
      }
      return response
    },
    setDataCompanySelector(dataCompany) {
      if (dataCompany) {
        this.marketplaceArray = dataCompany.map(element => {
          return {
            id: element.id ?? 0,
            text: element.name ?? '',
            organization_type: element.organization_type?.id ?? 0,
            organization_text: (element.organization_type?.name ?? '').toLowerCase() 
          }
        })
      }
    },
    setDataRow (dataTicket, pages = 0) {
      this.rows = dataTicket.map(ticket => {
        return {
          id: ticket.delivery?.id ?? 0,
          carrierName: ticket.carrier.name ?? '',
          classStatus : ticket.current_stage ? ticket.current_stage.code : '',
          classWaitingDays: ticket.waiting_days.color,
          clientName: ticket.current_status ? ticket.current_status.client_name : '',
          companyName: ticket.company.name ?? '',
          importedId : ticket.delivery?.imported_id ?? '',
          statusDate : this.formatDate(ticket.current_status.date),
          statusDeliveryName: ticket.current_delivery_status.name ?? '',
          statusTicket: ticket.current_stage ? ticket.current_stage.name : '',
          statusFulfillment: ticket.delivery?.extra_fields?.fulfillment?.name ? ticket.delivery?.extra_fields?.fulfillment?.name : '',
          subject: ticket.current_type ? ticket.current_type.name : '',
          textWaitingDays: `${ticket.waiting_days.days} ${ticket.waiting_days.text}` ?? '',
          trackNumber: ticket.delivery?.tracking_number ?? '',
          delivery_type: ticket.delivery_type ?? ''
        }
      })
      this.setPagination(pages)
    },
    setInitialData() {
      this.fieldsForm = [
        // {name: 'transportOrder', useSlot: true, containerClass: 'col-12 col-md-4 col-lg-3 col-xl-2'},
        { fieldType: 'FieldTags', label: 'Orden de transporte (OT)', name: 'tracking_number', placeholder: this.$t('OT'), tagsOption: {
          limitTagsText: this.$t('Limite de OT alcanzadas'),
          duplicateTagText: this.$t('Orden de transporte duplicada'),
          limit: 15
        }, disabled: this.loading?.tickets, containerClass: this.setClassField('OT')},
        {name: 'buttonModalOT', useSlot: true, containerClass: 'col-12 col-xl-2 mt-06 pending--button--modal'},
        {name: 'tagsMarketplaceSlot', useSlot: true, containerClass: this.setClassField('MKP')},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-11 col-lg-9 col-xl-6'},
        {name: 'exportGroupSlot', useSlot:true, containerClass: 'col-12 col-md-12 col-lg-12 col-xl-6'}
      ]
      this.schema = [
        {label: 'Asunto', key: 'subject'},
        {label: 'Estado Ticket', key: 'statusSlot', useSlot: true},
        {label: 'Seller', key: 'companyNameSlot', useSlot: true},
        {label: 'Envío', key: 'trackingInfoSlot', useSlot: true},
        {label: 'Fecha Estado del ticket', key:'dateStatusSlot', useSlot: true},
        {label: 'Estado envío', key: 'deliveryStatusSlot', useSlot: true},
        {label: 'Dias sin respuesta del seller', key: 'waitingDaysSlot', useSlot: true},
        {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ]
      this.metaFilter = this.$route.meta.filter
    },
    setQueryParams (prevQueryParams, needPage = false) {
      let queryParams = {
        ...prevQueryParams,
        order_by: 'WAITING_DAYS_ASC',
        waiting_days: true,
        not_datetime: true,
        last_message_user_role: 'admin'
      }
      if (needPage) {
        queryParams.limit = this.pagination.limit
        queryParams.page = this.pagination.page
      }
      const paramsOrganization = this.setOrganizationParams()

      queryParams = {...queryParams, ...paramsOrganization}
      return queryParams
    },
    setPagination (pages = 0) {      
      if (pages > 0) { 
        this.pagination.total = pages
      } else if (pages === 0 && this.pagination.calculateCountPage === true) {
        this.pagination.total = 0
      }
    },
    showModalAttachment () {
      this.$bvModal.show('uploadAttachment')
    },
    openTicket(data) {
      window.open(`${environment.platformUrl}/${ !!data && !!data.delivery_type && data.delivery_type === 'return' ? 'return-deliveries' : 'tickets'}/${data.id}`, '_blank')
    },
    openDelivery(data) {
      window.open(`${environment.platformUrl}/${ !!data && !!data.delivery_type && data.delivery_type === 'return' ? 'return-deliveries' : 'deliveries'}/${data.id}`, '_blank')
    },
    setOrganizationParams () {
      const paramsOrganization = {count : this.pagination.calculateCountPage}
      this.pagination.noShowDigits = false
      switch (this.dataUser.role) {
      case 'ecommerce':
        paramsOrganization.company = this.dataUser.organization.code ?? ''
        break
      case 'marketplace':
        paramsOrganization.marketplace = this.dataUser.organization.code ?? ''
        this.checkOrganizationId(paramsOrganization)
        break
      case 'admin':
        this.pagination.noShowDigits = true
        paramsOrganization.count = false
        this.checkOrganizationId(paramsOrganization)
        break
      default:
        if (this.dataUser.organization && this.dataUser.organization.id === 1) {
          paramsOrganization.marketplace = this.dataUser.organization.code ?? ''
          this.checkOrganizationId(paramsOrganization)
        } else {
          paramsOrganization.company = this.dataUser.organization.code ?? ''
        }
        break
      }
      return paramsOrganization
    },

    checkOrganizationId(paramsOrganization) {
      if (this.marketplaceSelect?.id) {
        const textOrganization = this.marketplaceSelect?.organization_text === 'seller' ? 'company_id' : 'marketplace_id'
        paramsOrganization[textOrganization] = this.marketplaceSelect?.id
      }
      return paramsOrganization
    }
  }
}
</script>

<style>
.pending--button--modal {
  align-self: center;
  text-align: center;
}
</style>
